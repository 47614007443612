<template>
  <div class="wrap-login vh-100">
    <div class="header">
      <img src="/logo.jpg" alt="IRPEN" />
    </div>

    <b-container class="pt-5">
      <nuxt />
    </b-container>
  </div>
</template>

<script>
import '../assets/style/typography.styl'

export default {
  name: "unsigned",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="stylus">
.wrap-login
  background: #f2f2f2;
  font-family: "Gotham Rounded Book";

  .header {
    background: #fff;
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 0 3px rgba(0,0,0,.1);
  }

  .header img {
    max-width: 260px;
  }

.box-form-login
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 420px;
  margin: 0 auto;
  padding: 25px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 0 2px 0 rgba(0, 0, 0, 0.07);

  button[type=submit]
    font-size: 1rem;
    font-family: "Gotham Rounded Book";

  .box-icone-envelope
    position: relative;

  .box-icone-envelope:after
    position: absolute;
    content: "";
    background: #f2f2f2 url("/ic-envelope.svg") no-repeat;
    background-size: 17px;
    background-position: center;
    width: 46px;
    height: 44px;
    top: 1px;
    left: 1px;
    border-radius: 3px 0 0px 3px;

  .form-group
    margin-bottom: 15px;

  .form-group .input-lg
    min-height: 46px;
    color: #000000;
    padding-left: 56px;

  .form-group .input-lg:focus
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
</style>
