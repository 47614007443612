import { getAuthHeader } from "~/services/auth";

export const state = () => ({
  tiposAtos: []
})

export const mutations = {
  SET_TIPOS_ATOS: function (state, payload) {
    state.tiposAtos = payload || null;
  }
}

export const actions = {
  fetchTiposAtos: function (context, req = null) {
    return this.$axios
      .get(`${process.env.API_HOST}/egiston/tipo-atos`, {
        headers: getAuthHeader(req)
      })
      .then(response => {
        context.commit("SET_TIPOS_ATOS", response.data);
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  }

}
