import Cookie from "js-cookie";

export default {
  logout: function () {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('me')
    }
    Cookie.remove('token')
    Cookie.remove("me");
    return true;
  },

  setToken: function (token) {
    window.localStorage.setItem("token", token);
    Cookie.set("token", token);
  },
};

export const storageMe = me => {
  window.localStorage.setItem('me', JSON.stringify(me))
  Cookie.set("me", me);
};

export const getAuthHeader = (req = null) => {
  return { 'Authorization': req ? decodeURIComponent(getTokenFromCookie(req)) : localStorage.getItem('token') }
}

export const getTokenFromLocalStorage = () => {
  const token = typeof localStorage !== 'undefined' ? localStorage.getItem('token') : undefined
  return token || undefined
}

export const getTokenFromCookie = (req) => {
  if (!req.headers.cookie) return
  const tokenCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith('token='))
  if (!tokenCookie) return
  return tokenCookie.split('=')[1]
}

export const getMeFromCookie = req => {
  if (!req || !req.headers.cookie) return;
  const mef = req.headers.cookie
    .split(";")
    .find(c => c.trim().startsWith("me="));
  if (!mef) return;
  const me = decodeURIComponent(mef.trim());
  if (!me) return;
  return JSON.parse(me.substring(String("me=").length, me.length));
};
