import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _68a42387 = () => interopDefault(import('../pages/ativacao-mfa.vue' /* webpackChunkName: "pages/ativacao-mfa" */))
const _2e9957de = () => interopDefault(import('../pages/cadastros/index.vue' /* webpackChunkName: "pages/cadastros/index" */))
const _337f9ced = () => interopDefault(import('../pages/casamentos/index.vue' /* webpackChunkName: "pages/casamentos/index" */))
const _7337a833 = () => interopDefault(import('../pages/configuracoes/index.vue' /* webpackChunkName: "pages/configuracoes/index" */))
const _2d779518 = () => interopDefault(import('../pages/contrato.vue' /* webpackChunkName: "pages/contrato" */))
const _07d92427 = () => interopDefault(import('../pages/gerador-selo/index.vue' /* webpackChunkName: "pages/gerador-selo/index" */))
const _1f3c84b6 = () => interopDefault(import('../pages/livro-e/index.vue' /* webpackChunkName: "pages/livro-e/index" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _57735f12 = () => interopDefault(import('../pages/minuta-teste.vue' /* webpackChunkName: "pages/minuta-teste" */))
const _3c2214ff = () => interopDefault(import('../pages/nascimentos/index.vue' /* webpackChunkName: "pages/nascimentos/index" */))
const _7b5df22f = () => interopDefault(import('../pages/natimortos/index.vue' /* webpackChunkName: "pages/natimortos/index" */))
const _c4444ebe = () => interopDefault(import('../pages/obitos/index.vue' /* webpackChunkName: "pages/obitos/index" */))
const _36c2d9dc = () => interopDefault(import('../pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _f1e06b72 = () => interopDefault(import('../pages/relatorios-orgaos/index.vue' /* webpackChunkName: "pages/relatorios-orgaos/index" */))
const _41e629d5 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _37e63a44 = () => interopDefault(import('../pages/usuarios/index.vue' /* webpackChunkName: "pages/usuarios/index" */))
const _114b81fb = () => interopDefault(import('../pages/websocket.vue' /* webpackChunkName: "pages/websocket" */))
const _11385470 = () => interopDefault(import('../pages/comunicacoes/crc.vue' /* webpackChunkName: "pages/comunicacoes/crc" */))
const _88be144a = () => interopDefault(import('../pages/comunicacoes/ressarcimento.vue' /* webpackChunkName: "pages/comunicacoes/ressarcimento" */))
const _bac1c4d2 = () => interopDefault(import('../pages/comunicacoes/selodigital.vue' /* webpackChunkName: "pages/comunicacoes/selodigital" */))
const _4e9fc456 = () => interopDefault(import('../pages/configuracoes/certificado/index.vue' /* webpackChunkName: "pages/configuracoes/certificado/index" */))
const _635e5ef2 = () => interopDefault(import('../pages/configuracoes/minutas/index.vue' /* webpackChunkName: "pages/configuracoes/minutas/index" */))
const _04c5b9df = () => interopDefault(import('../pages/faturas/admin/index.vue' /* webpackChunkName: "pages/faturas/admin/index" */))
const _6e7d1540 = () => interopDefault(import('../pages/relatorios/emolumentos/index.vue' /* webpackChunkName: "pages/relatorios/emolumentos/index" */))
const _d070957a = () => interopDefault(import('../pages/relatorios/indice-por-livro/index.vue' /* webpackChunkName: "pages/relatorios/indice-por-livro/index" */))
const _66132d62 = () => interopDefault(import('../pages/relatorios/proclamas/index.vue' /* webpackChunkName: "pages/relatorios/proclamas/index" */))
const _10261845 = () => interopDefault(import('../pages/relatorios/protocolo/index.vue' /* webpackChunkName: "pages/relatorios/protocolo/index" */))
const _fb1e918c = () => interopDefault(import('../pages/relatorios/selos-gerados/index.vue' /* webpackChunkName: "pages/relatorios/selos-gerados/index" */))
const _727926b9 = () => interopDefault(import('../pages/cadastros/_id.vue' /* webpackChunkName: "pages/cadastros/_id" */))
const _ab8c12d6 = () => interopDefault(import('../pages/casamentos/_id.vue' /* webpackChunkName: "pages/casamentos/_id" */))
const _462fc94a = () => interopDefault(import('../pages/configuracoes/_id.vue' /* webpackChunkName: "pages/configuracoes/_id" */))
const _451fbaf2 = () => interopDefault(import('../pages/faturas/_id.vue' /* webpackChunkName: "pages/faturas/_id" */))
const _541ce54d = () => interopDefault(import('../pages/livro-e/_id.vue' /* webpackChunkName: "pages/livro-e/_id" */))
const _46df4b27 = () => interopDefault(import('../pages/nascimentos/_id.vue' /* webpackChunkName: "pages/nascimentos/_id" */))
const _3cd0b457 = () => interopDefault(import('../pages/natimortos/_id.vue' /* webpackChunkName: "pages/natimortos/_id" */))
const _b2c8e16e = () => interopDefault(import('../pages/obitos/_id.vue' /* webpackChunkName: "pages/obitos/_id" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ativacao-mfa",
    component: _68a42387,
    name: "ativacao-mfa"
  }, {
    path: "/cadastros",
    component: _2e9957de,
    name: "cadastros"
  }, {
    path: "/casamentos",
    component: _337f9ced,
    name: "casamentos"
  }, {
    path: "/configuracoes",
    component: _7337a833,
    name: "configuracoes"
  }, {
    path: "/contrato",
    component: _2d779518,
    name: "contrato"
  }, {
    path: "/gerador-selo",
    component: _07d92427,
    name: "gerador-selo"
  }, {
    path: "/livro-e",
    component: _1f3c84b6,
    name: "livro-e"
  }, {
    path: "/login",
    component: _b4d4bac6,
    name: "login"
  }, {
    path: "/minuta-teste",
    component: _57735f12,
    name: "minuta-teste"
  }, {
    path: "/nascimentos",
    component: _3c2214ff,
    name: "nascimentos"
  }, {
    path: "/natimortos",
    component: _7b5df22f,
    name: "natimortos"
  }, {
    path: "/obitos",
    component: _c4444ebe,
    name: "obitos"
  }, {
    path: "/password-reset",
    component: _36c2d9dc,
    name: "password-reset"
  }, {
    path: "/relatorios-orgaos",
    component: _f1e06b72,
    name: "relatorios-orgaos"
  }, {
    path: "/reset-password",
    component: _41e629d5,
    name: "reset-password"
  }, {
    path: "/usuarios",
    component: _37e63a44,
    name: "usuarios"
  }, {
    path: "/websocket",
    component: _114b81fb,
    name: "websocket"
  }, {
    path: "/comunicacoes/crc",
    component: _11385470,
    name: "comunicacoes-crc"
  }, {
    path: "/comunicacoes/ressarcimento",
    component: _88be144a,
    name: "comunicacoes-ressarcimento"
  }, {
    path: "/comunicacoes/selodigital",
    component: _bac1c4d2,
    name: "comunicacoes-selodigital"
  }, {
    path: "/configuracoes/certificado",
    component: _4e9fc456,
    name: "configuracoes-certificado"
  }, {
    path: "/configuracoes/minutas",
    component: _635e5ef2,
    name: "configuracoes-minutas"
  }, {
    path: "/faturas/admin",
    component: _04c5b9df,
    name: "faturas-admin"
  }, {
    path: "/relatorios/emolumentos",
    component: _6e7d1540,
    name: "relatorios-emolumentos"
  }, {
    path: "/relatorios/indice-por-livro",
    component: _d070957a,
    name: "relatorios-indice-por-livro"
  }, {
    path: "/relatorios/proclamas",
    component: _66132d62,
    name: "relatorios-proclamas"
  }, {
    path: "/relatorios/protocolo",
    component: _10261845,
    name: "relatorios-protocolo"
  }, {
    path: "/relatorios/selos-gerados",
    component: _fb1e918c,
    name: "relatorios-selos-gerados"
  }, {
    path: "/cadastros/:id",
    component: _727926b9,
    name: "cadastros-id"
  }, {
    path: "/casamentos/:id",
    component: _ab8c12d6,
    name: "casamentos-id"
  }, {
    path: "/configuracoes/:id",
    component: _462fc94a,
    name: "configuracoes-id"
  }, {
    path: "/faturas/:id?",
    component: _451fbaf2,
    name: "faturas-id"
  }, {
    path: "/livro-e/:id?",
    component: _541ce54d,
    name: "livro-e-id"
  }, {
    path: "/nascimentos/:id",
    component: _46df4b27,
    name: "nascimentos-id"
  }, {
    path: "/natimortos/:id",
    component: _3cd0b457,
    name: "natimortos-id"
  }, {
    path: "/obitos/:id",
    component: _b2c8e16e,
    name: "obitos-id"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
