import { getAuthHeader } from "~/services/auth";

export const state = () => ({
  step: null,
  casamentos: null,
  nascimentos: null,
  obitos: null,
  natimortos: null,
  livroe: null,
  emolumentos: [],
  filters: {
    casamentos: {
      search: {
        text: '',
        text2: '',
      },
      selectedSearchOption: { name: 'dt_assento' },
      orderBy: null,
      naoHabilitados: false,
      somenteHabilitadosSemCasamento: false,
      somenteJusticaBairros: false,
      tipoCasamento: 0,
    },
    nascimentos: {
      search: {
        text: '',
        text2: '',
      },
      orderBy: null,
      selectedSearchOption: { name: 'dt_assento' },
    },
    obitos: {
      search: {
        text: '',
        text2: '',
      },
      orderBy: null,
      selectedSearchOption: { name: 'dt_assento' },
    },
    natimortos: {
      search: {
        text: '',
        text2: '',
      },
      orderBy: null,
      selectedSearchOption: { name: 'dt_assento' },
    },
    livroe: {
      search: {
        text: '',
        text2: '',
      },
      orderBy: null,
      selectedSearchOption: { name: 'dt_assento' },
    }
  },
});

export const mutations = {
  SET_STEP: (state, payload) => state.step = payload,
  SET_ATOS: (state, { payload, ...params } = {}) => {
    if (state[params.type] && state[params.type].content.length > 0 && ((params.page > 1 && params.page !== false))) {
      let lastItems = state[params.type].content
      state[params.type] = payload || null
      state[params.type].content = lastItems.concat(payload.content)
    } else {
      state[params.type] = payload || null
    }
  },
  SET_FILTERS: (state, { payload, type }) => state.filters[type] = { ...state.filters[type], ...payload },
  SET_EMOLUMENTOS: (state, { payload, ...params } = {}) => {
    if (state.emolumentos && state.emolumentos.length > 0 && ((params.page > 1 && params.page !== false))) {
      let lastEmolumentos = state.emolumentos
      state.emolumentos = lastEmolumentos.concat(payload)
    } else {
      state.emolumentos = payload || null
    }
  }
}

export const actions = {
  fetch: function (context, { type = null, req = null, page = false, ...params } = {}) {
    if (!type) return false
    
    let uri = `${process.env.API_HOST}/` + (type === 'natimortos' ? 'obitos?tipoAto=NATIMORTO' : type)
    let sep = `?`
    if(uri.search('[?]') > 0)    
      sep = `&`
    uri += page ? `${sep}page=${page}` : ''

    if (params.search) {
      uri += uri.search('[?]') >= 0 ? `&query=${params.search}` : `?query=${params.search}`
      uri += params.searchOptions ? (uri.search('[?]') >= 0 ? `&queryOptions=${params.searchOptions}` : `?queryOptions=${params.searchOptions}`) : ''
    }

    if (params.naoHabilitados) uri += uri.search('[?]') >= 0 ? `&naoHabilitados=true` : `?naoHabilitados=true`
    if (params.somenteHabilitadosSemCasamento) uri += uri.search('[?]') >= 0 ? `&somenteHabilitadosSemCasamento=true` : `?somenteHabilitadosSemCasamento=true`
    if (params.somenteJusticaBairros) uri += uri.search('[?]') >= 0 ? `&somenteJusticaBairros=true` : `?somenteJusticaBairros=true`
    if (params.orderBy) uri += uri.search('[?]') >= 0 ? `&order=${params.orderBy}` : `?order=${params.orderBy}`
    if (params.tipoCasamento) uri += uri.search('[?]') >= 0 ? `&tipoCasamento=${params.tipoCasamento}` : `?tipoCasamento=${params.tipoCasamento}`
    if (params.pageSize) {
      uri += uri.search('[?]') >= 0 ? `&pageSize=${params.pageSize}` : `?pageSize=${params.pageSize}`
    } else {
      uri += uri.search('[?]') >= 0 ? `&pageSize=10` : `?pageSize=10`
    }

    return this.$axios.get(uri, { headers: getAuthHeader() })
      .then(response => {
        context.commit('SET_ATOS', { 'payload': response.data, 'page': page, type });
        return response.data
      });
  },

  fetchEmolumentos: function (context, { ...params } = {}) {

    if(!params.page)
      params.page = 1

    let uri = `${process.env.API_HOST}/ato/conta-receber/sintetico?page=${params.page}&data1=${params.data1}&data2=${params.data2}`;    
    if(params.numeroTipoAto)
      uri += `&numeroTipoAto=${params.numeroTipoAto}`

    return this.$axios.$get(uri, { headers: getAuthHeader() }).then(response => {
      context.commit('SET_EMOLUMENTOS', { 'payload': response.regs.content, 'page': params.page, 'vlTotal': response.vlTotal, 'vlEmolumentos': response.vlEmolumentos });
      return response;
    });
  }
}
