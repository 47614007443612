<template>
  <div>
    <b-sidebar id="sideBarMenu" no-header shadow backdrop>
      <div class="wrap-menu" v-for="(section, i) in menu" :key="i">
        <h2 class="title-menu">{{ section.name }}</h2>
        <ul>
          <li v-for="(item, j) in section.items" :key="j">
            <nuxt-link :to="item.path">
              <span class="ic-menu">
                <img :src="item.icon" :alt="item.title" />
              </span>
              <span>{{ item.title }}</span>
            </nuxt-link>
          </li>
        </ul>
      </div>
    </b-sidebar>

    <section id="main">
      <Header />

      <div style="padding-top:80px; min-height: 100vh;">
        <nuxt />
      </div>
      <b-modal id="change-senha-user" title="Alterar senha" hide-footer>
        <AlterarSenha />
      </b-modal>
    </section>
  </div>
</template>

<script>
import Header from '~/components/Header'
import AlterarSenha from '~/components/AlterarSenha.vue'
import "~/assets/style/app.styl";

export default {
  name: "DefaultLayout",
  middleware: ["check-auth"],
  components: { Header, AlterarSenha },
  data() {
    return {
      sideBarMenu: false,
    };
  },
  computed: {
    menu: function () {
      if (this.$store.state.me.cpf === '99999999999') {
        return this.$store.state.menu.filter(menu => menu.type === 'ADMIN');
      }
      return this.$store.state.menu.filter(menu => {
        if (this.$store.state.me.cargo === 'AUXILIAR') {
          return !menu.hasOwnProperty('type') && menu.name !== 'Configurações'
        }
        return !menu.hasOwnProperty('type')
      }) || [];
    }
  },
  async mounted() {
    //this.$Tawk.$startChat();
    this.updateTawk()

    if (this.$store.state.certificadoCadastrado == null) await this.$store.dispatch("fetchTemCertificadoCadastrado")
    if (this.$store.state.certificadoCadastrado === false) {
      this.$bvToast.toast('Certificado Digital não cadastrado, sendo assim não será possível Gerar Selo Digital, pedimos a gentileza de acessar o Menu, Configurações -> Certificado Digital', {
        title: `Informação Importante`,
        variant: 'danger',
      })
    }
  },
  methods: {
    updateTawk() {
      this.$nextTick(() => {
        if (!window.Tawk_API.setAttributes || typeof window.Tawk_API === 'undefined') {
          return setTimeout(() => {
            return this.updateTawk()
          }, 3000);
        }

        let name = this.$store.state.me.nome + ' - ' + this.$store.state.me.comarca
        if (name.length > 50)
          name = name.substring(0, 49)

        this.$Tawk.$updateChatUser({
          name: name,
          email: this.$store.state.me.email
        })
      })
    }
  }
};
</script>

<style lang="stylus" scoped>
#main {
  background: #f2f2f2;
}
</style>
