import { getTokenFromCookie, getTokenFromLocalStorage, getMeFromCookie } from '~/services/auth'

export default function ({store, req, redirect}) {
  // If nuxt generate, pass this middleware
  if (process.server && !req) {
    redirect('/login')
    return
  }

  const token = process.server || getTokenFromLocalStorage() === undefined ? getTokenFromCookie(req) : getTokenFromLocalStorage()

  if (!token) {
    redirect('/login')
    return
  }

  if (!store.getters.isAuthenticated) {
    store.commit('SET_ME', getMeFromCookie(req))
  }
}
