import { getAuthHeader, storageMe } from '~/services/auth'
import { toQueryString } from '~/services/utils'

export const state = () => ({
  API_HOST: null,
  time: null,
  me: null,
  menu: [
    {
      name: "Serviços",
      items: [
        { icon: "/ic-nascimento.svg", title: "Nascimento", path: "/nascimentos" },
        { icon: "/ic-casamento.svg", title: "Casamento", path: "/casamentos" },
        { icon: "/ic-obito.svg", title: "Óbito", path: "/obitos" },
        { icon: "/ic-natimorto.svg", title: "Natimorto", path: "/natimortos" },
        { icon: "/ic-livro-e.svg", title: "Livro E", path: "/livro-e" },
        { icon: "/ic-livro-e.svg", title: "Geração de Selo Avulso", path: "/gerador-selo" }
      ]
    },
    {
      name: "Comunicações",
      items: [
        { icon: "/ic-crc.svg", title: "CRC Nacional", path: "/comunicacoes/crc" },
        { icon: "/ic-ressarcimento.svg", title: "Ressarcimento", path: "/comunicacoes/ressarcimento" },
        { icon: "/ic-ressarcimento.svg", title: "Selo Digital", path: "/comunicacoes/selodigital" }
      ]
    },
    {
      name: "Relatórios",
      items: [
        { icon: "/ic-custas.svg", title: "Protocolo", path: "/relatorios/protocolo" },
        { icon: "/ic-selo-digital.svg", title: "Selos Gerados", path: "/relatorios/selos-gerados" },
        { icon: "/ic-custas.svg", title: "Emolumentos", path: "/relatorios/emolumentos" },
        { icon: "/ic-custas.svg", title: "Órgãos Públicos", path: "/relatorios-orgaos" },
        { icon: "/ic-custas.svg", title: "Índice por Livro", path: "/relatorios/indice-por-livro" },
        { icon: "/ic-custas.svg", title: "Proclamas", path: "/relatorios/proclamas" },
      ]
    },
    {
      name: "Configurações",
      items: [
        { icon: "/ic-config-usuarios.svg", title: "Customização de Minutas", path: "/configuracoes/minutas" },
        { icon: "/ic-config-usuarios.svg", title: "Usuários", path: "/usuarios" },
        { icon: "/ic-config-usuarios.svg", title: "Certificado Digital", path: "/configuracoes/certificado" }
      ]
    },
    {
      name: "Serviços",
      items:
        [
          { icon: "/ic-crc.svg", title: "Cadastro de Serventias / Usuários", path: "/cadastros", blackList: [] },
          { icon: "/ic-crc.svg", title: "Faturamento", path: "/faturas/admin", blackList: [] },
        ],
      type: 'ADMIN'
    }
  ],
  acervos: [],
  orgaoExp: [],
  uf: [],
  tipoDocumento: [],
  paises: [],
  municipios: {},
  hospitais: [],
  locaisSepultamentos: [],
  livrosProtocolos: [],
  serventias: [],
  livroD: [],
  certificadoCadastrado: null
});

export const mutations = {
  setHOST: function (state, payload) {
    state.API_HOST = payload || null;
  },
  setTime: function (state, payload) {
    state.time = payload || null;
  },
  SET_ME: (state, payload) => state.me = payload || null,
  SET_ACERVOS: (state, payload) => state.acervos = payload,
  SET_UF: (state, payload) => state.uf = payload,
  SET_ORGAOEXP: (state, payload) => state.orgaoExp = payload,
  SET_TIPODOCUMENTO: (state, payload) => state.tipoDocumento = payload,
  SET_PAISES: (state, payload) => state.paises = payload,
  SET_MUNICIPIOS: (state, payload) => {
    let uf = Object.keys(payload)[0]
    state.municipios[uf] = payload[uf] || {}
  },
  SET_HOSPITAIS: (state, payload) => state.hospitais = payload,
  SET_LOCAIS_SEPULTAMENTOS: (state, payload) => state.locaisSepultamentos = payload,
  SET_LIVROS_PROTOCOLOS: (state, { payload, ...params } = {}) => {
    if (state.livrosProtocolos && state.livrosProtocolos.length > 0 && ((params.page > 1 && params.page !== false))) {
      let lastItems = state.livrosProtocolos
      state.livrosProtocolos = lastItems.concat(payload)
    } else {
      state.livrosProtocolos = payload || null
    }
  },
  SET_SERVENTIAS: (state, { payload, ...params } = {}) => {
    if (state.serventias && state.serventias.length > 0 && ((params.page > 1 && params.page !== false))) {
      let lastItems = state.serventias
      state.serventias = lastItems.concat(payload)
    } else {
      state.serventias = payload || null
    }
  },
  SET_LIVRO_D: (state, { payload, ...params } = {}) => {
    if (state.livroD && state.livroD.length > 0 && ((params.page > 1 && params.page !== false))) {
      let lastItems = state.livroD
      state.livroD = lastItems.concat(payload)
    } else {
      state.livroD = payload || null
    }
  },
  SET_CERTIFICADO_CADASTRADO: (state, payload) => state.certificadoCadastrado = payload
};

export const getters = {
  isAuthenticated: function (state) {
    return !!state.me
  },
  todayDate: (state) => {
    let day = state.time.getDate();
    let month = state.time.getMonth() + 1;
    let year = state.time.getFullYear();

    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  },
  addDaysToDate: (state) => (days) => {
    var date = new Date(state.time.getTime());

    date.setDate(date.getDate() + days)
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  }
};

export const actions = {
  nuxtServerInit({ commit }, { req, env }) {
    commit("setTime", new Date());
    commit("setHOST", env.API_HOST);
  },

  logout({ commit }, payload) {
    commit('SET_ME', null);
  },

  fetchMe: async function (context) {
    let me = await this.$axios.$get(`${process.env.API_HOST}/auth/me`, {
      headers: getAuthHeader()
    })
      .catch(function (error) {
        return error
      })
    context.commit('SET_ME', me)
    storageMe(me)
    return me
  },

  fetchAcervos: function (context, {oficioId, req = null}) {
    if(!oficioId) return false;
    return this.$axios
      .get(`${process.env.API_HOST}/oficios/${oficioId}/acervos`, {
        headers: getAuthHeader(req)
      })
      .then(response => {
        context.commit("SET_ACERVOS", response.data);
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  },

  fetchOrgaoExp: function (context, req = null) {
    return this.$axios
      .get(`${process.env.API_HOST}/orgao-expeditor-rg`, {
        headers: getAuthHeader(req)
      })
      .then(response => {
        context.commit("SET_ORGAOEXP", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  fetchTipoDocumento: function (context, req = null) {
    return this.$axios
      .get(`${process.env.API_HOST}/tipo-documento`, {
        headers: getAuthHeader(req)
      })
      .then(response => {
        context.commit(`SET_TIPODOCUMENTO`, response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  fetchPaises: function (context, req = null) {
    this.$axios
      .get(`${process.env.API_HOST}/paises/findAllByOrderByPaisNome`, {
        headers: getAuthHeader(req)
      })
      .then(response => {
        context.commit("SET_PAISES", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  fetchUF: function (context, req = null) {
    return this.$axios
      .get(`${process.env.API_HOST}/municipio/uf`, {
        headers: getAuthHeader(req)
      })
      .then(response => {
        context.commit("SET_UF", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },

  fetchMunicipios: function (context, uf = 'PR') {
    return this.$axios
      .get(`${process.env.API_HOST}/municipio/${uf}`, {
        headers: getAuthHeader()
      })
      .then(response => {
        let payload = {}
        payload[uf] = response.data
        context.commit("SET_MUNICIPIOS", payload);
        return response.data
      })
      .catch(error => {
        console.log(error);
      });
  },

  fetchHospitais: function (context) {
    return this.$axios
      .get(`${process.env.API_HOST}/hospitais`, {
        headers: getAuthHeader()
      })
      .then(response => {
        context.commit("SET_HOSPITAIS", response.data);
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  },

  fetchLocaisSepultamentos: function (context) {
    return this.$axios.get(`${process.env.API_HOST}/locais-sepultamento`, {
      headers: getAuthHeader()
    }).then(response => {
      context.commit("SET_LOCAIS_SEPULTAMENTOS", response.data);
      return response;
    }).catch(error => {
      console.log(error);
    })
  },

  fetchLivrosProtocolos: function (context, { page = false, ...params } = {}) {
    let uri = `${process.env.API_HOST}/atos/livro-protocolo`
    uri += page ? `?page=${page}` : ''

    if (params.filters && Object.keys(params.filters).length > 0) {
      for (let filter of Object.entries(params.filters)) {
        uri += uri.search('[?]') >= 0 ? `&${filter[0]}=${filter[1]}` : `?${filter[0]}=${filter[1]}`
      }
    } 
    return this.$axios.$get(`${uri}`, { headers: getAuthHeader() })
      .then((response) => {
        context.commit("SET_LIVROS_PROTOCOLOS", { payload: response.content, page: response.number + 1 });
        return response
      });
  },

  fetchServentias: function (context, { ...params } = {}) {
    let uri = `${process.env.API_HOST}/oficios`
    uri += toQueryString(params);

    return this.$axios.$get(`${uri}`, { headers: getAuthHeader() })
      .then((response) => {
        context.commit("SET_SERVENTIAS", { payload: response.content, page: response.number + 1 });
        return response
      })
  },

  fetchLivroD: function (context, { page = false, ...params } = {}) {
    let uri = `${process.env.API_HOST}/casamentos/livro-d`
    uri += page ? `?page=${page}` : ''

    if (params.filters && Object.keys(params.filters).length > 0) {
      for (let filter of Object.entries(params.filters)) {
        uri += uri.search('[?]') >= 0 ? `&${filter[0]}=${filter[1]}` : `?${filter[0]}=${filter[1]}`
      }
    } else {
      uri += (uri.search('[?]') >= 0 ? `&` : `?`) + `data1=01/01/2020&data2=29/12/2020`
    }
    return this.$axios.$get(`${uri}`, { headers: getAuthHeader() })
      .then((response) => {
        context.commit("SET_LIVRO_D", { payload: response.content, page: response.number + 1 });
        return response
      });
  },

  fetchTemCertificadoCadastrado: function(context, { ...params } = {}) {
    let uri = `${process.env.API_HOST}/oficios/temCertificadoEgiston`
    return this.$axios.$get(`${uri}`, { headers: getAuthHeader() })
      .then((response) => {
        context.commit("SET_CERTIFICADO_CADASTRADO", response);
        return response
      })
  }
};
