import { getAuthHeader } from "~/services/auth";

export const state = () => ({
  chavesSelos: [],
  keyStock: []
});

export const mutations = {
  SET_CHAVES_SELOS: (state, { payload, ...params } = {}) => {
    if (state.chavesSelos && state.chavesSelos.length > 0 && ((params.page > 1 && params.page !== false))) {
      let lastItems = state.chavesSelos
      state.chavesSelos = lastItems.concat(payload)
    } else {
      state.chavesSelos = payload || null
    }
  },
  SET_KEYSTOCK: (state, { payload } = {}) => {
    if (state.keyStock) {
      state.keyStock = payload
    }
  },
}

export const actions = {

  fetchKeyStock: function (context, { req = null, page = false, ...params } = {}) {
    let uri = `${process.env.API_HOST}/selo-digital/estoque`

    return this.$axios.get(uri, { headers: getAuthHeader() })
      .then(response => {
        context.commit('SET_KEYSTOCK', { 'payload': response.data });
        return response.data
      });
  },

  fetch: function (context, { req = null, page = false, ...params } = {}) {
    let uri = `${process.env.API_HOST}/selo-digital/listar-importacoes`
    uri += page ? `?page=${page}` : ''    

    return this.$axios.get(uri, { headers: getAuthHeader() })
      .then(response => {
        context.commit('SET_CHAVES_SELOS', { 'payload': response.data.content, 'page': page });
        return response.data
      });
  },
 
}
