export function cloneData(data) {
  return JSON.parse(JSON.stringify(data))
}

export function handleErrors(error) {
  if (!error.response) return;
  let errors = []
  if (error.response.data != null && error.response.data.message != null) {
    errors.push(error.response.data.message);
  } else if (error.response.data.errors != null) {
    for (var i = 0; i <= error.response.data.errors.length - 1; i++) {
      errors.push(error.response.data.errors[i].message || error.response.data.errors[i].detail);
    }
  }
  return errors
}

export function verifyOficial() {
  return !['OFICIAL', 'OFICIAL_SUBSTITUTO'].includes(this.$store.state.me.cargo) || this.$store.state.me.cpf === '99999999999'
}

export function addDays(date, days) {
  var str = date.split('/')
  var datex = str[2] + '/' + str[1] + '/' + str[0]
  var mydate = new Date(datex);

  mydate.setDate(mydate.getDate() + days)
  let day = mydate.getDate();
  let month = mydate.getMonth() + 1;
  let year = mydate.getFullYear();

  return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
}

export const Convert = {
  methods: {
    toCurrency: function (value, locale = 'pt-BR', currency = 'BRL') {
      return parseFloat(value).toLocaleString(locale, {
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    toFloat: function (value) {
      if (!isNaN(value)) return value
      var x = value.replace(/\./g, "")
      x = x.replace(",", ".");
      return parseFloat(x);
    }
  }
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function removerAcentos(str) {

    if(!str)
      return "";

    var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    var novastr="";
    var troca;

    for(var i=0; i<str.length; i++) {
        troca=false;
        for (var a=0; a<com_acento.length; a++) {
            if (str.substr(i,1)==com_acento.substr(a,1)) {
                novastr+=sem_acento.substr(a,1);
                troca=true;
                break;
            }
        }
        if (troca==false) {
            novastr+=str.substr(i,1);
        }
    }
    return novastr;
 
}

export function toQueryString(params) {
  let queryString = Object.entries(params)
    .filter(([key, val]) => val)
    .map(([key, val]) => {
      return `${key}=${val}`
    }).join('&');
  let tamanho = queryString.length + 1;
  return queryString.padStart(tamanho, '?');
}

export function cleanCpf(value) {
  return value
    .replace(".", "")
    .replace(".", "")
    .replace("-", "")
}

export const DateHelper = {
  addDays: function (aDate, numberOfDays) {
    aDate.setDate(aDate.getDate() + numberOfDays); // Add numberOfDays
    return aDate;                                  // Return the date
  },
  format: function format(date) {
    return [
      ("0" + date.getDate()).slice(-2),           // Get day and pad it with zeroes
      ("0" + (date.getMonth() + 1)).slice(-2),      // Get month and pad it with zeroes
      date.getFullYear()                          // Get full year
    ].join('/');                                   // Glue the pieces together
  }
}

export const FormRules = {
  data() {
    return {
      rules: {
        required: value => {
          if (typeof value === 'undefined' || value === null) return null
          return value !== '' ? true : false
        },
        name: value => {
          if (typeof value === 'undefined' || value === null) return null
          return /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]+(\s[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]+)/.test(value)
        },
        onlyWord: value => {
          if (typeof value === 'undefined' || value === null) return null
          return /\w/.test(value)
        },
        onlyNumber: value => {
          if (typeof value === 'undefined' || value === null) return null
          return /\d/.test(value)
        },
        date: value => {
          if (typeof value === 'undefined' || value === null) return null
          return /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](18|19|20)\d\d$/.test(
            value
          )
        },
        dateAfter: (date, minDate) => {
          if (typeof date === 'undefined' || date === null) return null

          let dateValidated = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](18|19|20)\d\d$/.test(
            date
          )
          if (!dateValidated) return false

          if (!minDate)
            minDate = this.$store.getters.todayDate

          let [day1, month1, year1] = minDate.split(' ')[0].split('/')
          let dateOne = new Date(parseInt(year1), parseInt(month1 - 1), parseInt(day1))

          let [day2, month2, year2] = date.split(' ')[0].split('/')
          let dateTwo = new Date(year2, parseInt(month2 - 1), day2)

          return dateTwo > dateOne
        },
        dateBefore: (date, maxDate) => {
          if (typeof date === 'undefined' || date === null) return null

          let dateValidated = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](18|19|20)\d\d$/.test(
            date
          )
          if (!dateValidated) return false

          if (!maxDate)
            maxDate = this.$store.getters.todayDate

          let [day1, month1, year1] = maxDate.split(' ')[0].split('/')
          let dateOne = new Date(parseInt(year1), parseInt(month1 - 1), parseInt(day1))

          let [day2, month2, year2] = date.split(' ')[0].split('/')
          let dateTwo = new Date(year2, parseInt(month2 - 1), day2)

          return dateTwo <= dateOne
        },
        time: (value) => {
          if (typeof value === 'undefined' || value === null) return null
          return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
        },
        timeWithSeconds: (value) => {
          if (typeof value === 'undefined' || value === null) return null
          return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(value)
        },
        cpf: value => {
          if (typeof value === 'undefined' || value === null) return null

          let cpf = value.replace(/\./g, "").replace("-", "")

          if (cpf == "99999999999")
            return true

          if (cpf.length < 11 || cpf == "00000000000" || cpf == "11111111111" ||
            cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" ||
            cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" ||
            cpf == "88888888888" || cpf == "01234567890") return false

          let Soma
          let Resto
          Soma = 0

          for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
          }
          Resto = (Soma * 10) % 11

          if ((Resto === 10) || (Resto === 11)) Resto = 0
          if (Resto !== parseInt(cpf.substring(9, 10))) return false

          Soma = 0
          for (let i = 1; i <= 10; i++) {
            Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
          }
          Resto = (Soma * 10) % 11

          if ((Resto === 10) || (Resto === 11)) Resto = 0
          if (Resto !== parseInt(cpf.substring(10, 11))) return false

          return true
        },
        cep: value => {
          if (typeof value === 'undefined' || value === null) return null
          return /^\d{5}-\d{3}$/.test(value)
        },
        email: (value) => {
          if (!value || value === undefined || value === '') {
            return null
          }
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value)
        },
        phone: (value) => {
          if (!value || value === undefined || value === '') {
            return null
          }
          return value.length >= 14 || false
        },
        dnv: (value) => {
          if (typeof value === 'undefined' || value === null) return null
          return /^\d{2}-\d{8}-\d{1}$/.test(value)
        }
      },
      inputsRequireds: [],
      inputsWithErrors: []
    }
  },
  methods: {
    validateRules: function (elementRef) {
      if (!elementRef) return;
      let errors = 0;
      this.inputsRequireds = []
      this.inputsWithErrors = []
      elementRef.querySelectorAll("input, select, textarea").forEach(element => {
        if (
          (element.getAttribute("required") &&
            element.getAttribute("required") !== "") ||
          (element.getAttribute("rules") &&
            element.getAttribute("rules") !== "")
        ) {
          let rules = element.getAttribute("rules") ? element.getAttribute("rules").split(",") : false;

          if (rules) {
            rules.map(rule => {
              if (
                this.rules.hasOwnProperty(rule) &&
                this.rules[rule](element.value) === false
              ) {
                this.inputsWithErrors.push(element);
                errors++;
              }
            });
          } else if (element.value.trim() === "") {
            this.inputsWithErrors.push(element);
            errors++;
          }
          this.inputsRequireds.push(element);
        }
      });
      return errors;
    },
    validateClass: function (rule, showOnlyValid = false, value = null, e = false) {
      if (rule === null) {
        return null
      }

      if (e === false) {
        if (rule)
          return 'is-valid'
        else if (!rule && showOnlyValid && !value)
          return ''
        else
          return 'is-invalid'
      }

      if (rule) {
        e.target.classList.add('is-valid')
        e.target.classList.remove('is-invalid')
      } else {
        e.target.classList.add('is-invalid')
        e.target.classList.remove('is-valid')
      }
    },
    validateBlankFields: function () {
      this.inputsRequireds.forEach(element => {
        if (element.disabled === false && (element.value === null || element.value === '')) {
          element.classList.add('is-invalid')
        }
      })
    },
    removeValidateClass: function (elementRef) {
      if (!elementRef) return;
      elementRef.querySelectorAll("input, select, textarea").forEach(element => {
        element.classList.remove('is-invalid')
      })
    }
  }
}
