<template>
  <b-row id="main-header" tag="header" align-v="center" align-h="between" no-gutters>
    <b-col lg="5" class="d-flex align-items-center">
      <b-button :class="['hamb']" v-b-toggle.sideBarMenu variant="link" class="ml-4 shadow-none">
        <span />
        <span />
        <span />
      </b-button>

      <div class="logo ml-3">
        <b-button to="/" variant="link" class="p-0">
          <img src="/logo.jpg" class="img-fluid" alt="Irpen" />
        </b-button>
      </div>

      <div class="box-busca ml-3">
        <vue-typeahead-bootstrap v-model="query" :data="regsBuscaCpf" :serializer="item => item.texto" @hit="selectedRegBuscaCpf" placeholder="Consulta rápida por Nome ou CPF">
          <template slot="append">
            <b-button class="btn-outline-secondary" id="button-addon2">
              <img src="/ic-search.svg" alt="Pesquisar" />
            </b-button>
          </template>
        </vue-typeahead-bootstrap>
      </div>
    </b-col>

    <b-col cols="auto" class="justify-content-center">
      <!-- V11 -->
      <b-avatar-group>
        <b-avatar
          :variant="rs.quantidade > 20 ? 'light' : 'danger'"
          badge-offset="-1.2em"
          :text="rs.quantidade.toString()"
          badge-variant="primary"
          :key="`${rs.tipo}${rs.versao}`"
          v-for="rs in keyStock"
          class="mx-2"
        >
          <template #badge>
            <span style="font-size: 9px;">{{rs.tipo}}</span>
            <br />
            <div style="font-size: 8px; margin-top:-1px">v{{rs.versao}}</div>
          </template>
        </b-avatar>
      </b-avatar-group>
    </b-col>

    <b-col cols="3" class="d-flex align-items-center justify-content-end">
      <a href="https://regina.blob.core.windows.net/downloads/Manual_Regina.pdf" class="a-icon-help mx-4" target="_blank">
        <img src="/ic-help.svg" alt="Ajuda" />
      </a>

      <div v-if="$store.state.certificadoCadastrado === false">
        <a href="#" class="a-icon-alerta" id="popover-target-1">
          <img src="/ic-alertas.svg" alt="Notificações" />
        </a>
        <b-popover target="popover-target-1" triggers="click" placement="bottom">
          <template #title>Notificações (1)</template>
          Certificado Digital não cadastrado, sendo assim não será possível gerar Selo Digital, para cadastrar acesse:
          <b>Configurações</b> ->
          <b>Certificado Digital</b>
        </b-popover>
      </div>

      <div class="box-drop" style="overflow: hidden">
        <a href="#" @click="usuarioMenu = !usuarioMenu" :class="['box-user', 'px-3', 'd-flex', {active: usuarioMenu}]">
          <div style="overflow: hidden; width: 90%; display: inline-block; white-space: nowrap; text-overflow: ellipsis;">{{me.nome}}</div>
          <img class="arrow-down mx-2" src="/ic-arrow-down.svg" alt="Usuário" />
        </a>
        <div class="box-info pl-3" v-if="me.oficioNome">
          <div style="overflow: hidden; white-space: nowrap;text-overflow: ellipsis;max-width:200px">
            <span>{{me.oficioNome}}</span>
          </div>
          <span>CNS {{me.cns}}</span>
        </div>
        <ul role="menu" :class="['submenu-topo arrow-box',{'d-none': !usuarioMenu}]">
          <li role="presentation">
            <a :href="`/configuracoes/${$store.state.me.oficioId}`" v-if="$store.state.me.cargo !== 'AUXILIAR'" role="menuitem">Configurações</a>
          </li>
          <li role="presentation">
            <a href="#" @click="$bvModal.show('change-senha-user')" role="menuitem">Alterar Senha</a>
          </li>
          <li role="presentation" v-if="me.cpf != '99999999999'">
            <a :href="`/faturas`" role="menuitem">Faturas</a>
          </li>
          <li role="presentation">
            <a href="/contrato" role="menuitem">Contrato</a>
          </li>
          <li role="presentation">
            <a href="#" @click="sair" role="menuitem">Sair</a>
          </li>
        </ul>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import authService from "~/services/auth";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import 'bootstrap/scss/bootstrap.scss';
import { getAuthHeader } from "~/services/auth";

export default {
  name: "Header",
  components: { VueTypeaheadBootstrap },
  data() {
    return {
      usuarioMenu: false,
      query: null,
      regsBuscaCpf: [],
      searchTimeout: null,
      estoque: '10.0'
    };
  },
  watch: {
    query(val) {
      clearTimeout(this.searchTimeout);

      if (val != null && val.length >= 3) {
        this.searchTimeout = setTimeout(() => {
          this.$axios.get(`atos/buscar/${val}`, { headers: getAuthHeader() })
            .then((res) => this.regsBuscaCpf = res.data)
        }, 1500);
      }
    }
  },
  mounted() {
    if (this.$store.state.selo.keyStock)
      this.$store.dispatch('selo/fetchKeyStock');
  },
  computed: {
    me: function () {
      return this.$store.state.me || {};
    },
    keyStock: function () {
      return this.$store.state.selo.keyStock;
    },
  },
  methods: {
    selectedRegBuscaCpf(item) {
      this.query = null
      window.location = '/' + item.url + '/' + item.objetoId;
    },
    sair() {
      const confirmation = confirm("Deseja realmente encerrar esta sessão?");
      if (!confirmation) return false;
      this.$Tawk.$endChat()
      authService.logout();
      document.location.reload(true);
    },

  },
};
</script>

<style lang="stylus">
#main-header {
  position: fixed;
  width: 100%;
  min-height: 80px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: top 0.3s;
  font-family: 'Gotham Rounded Book', sans-serif;
  font-size: 0.9rem;

  .box-busca {
    width: 100%;

    .input-group {
      box-shadow: 2px 1px 3px #efefef;

      .form-control {
        border-right: none;
      }

      button {
        border: 1px solid #ced4da;
        border-left: none;
        background: transparent;
      }
    }
  }

  a.a-icon-help:hover {
    opacity: 0.9;
  }

  a.a-icon-alerta {
    margin: 0 10px 0 10px;
    position: relative;
    top: -2px;
  }

  a.a-icon-alerta:hover {
    opacity: 0.9;
  }

  .box-switch {
    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
      margin-left: 0;
    }

    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
      right: 0px;
    }

    .onoffswitch {
      position: relative;
      width: 96px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .onoffswitch-checkbox {
      display: none;
    }

    .onoffswitch-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 2px solid #ddd;
      border-radius: 20px;
      margin-bottom: 0;
    }

    .onoffswitch-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
    }

    .onoffswitch-inner:before, .onoffswitch-inner:after {
      display: block;
      float: left;
      width: 50%;
      height: 30px;
      padding: 0;
      line-height: 30px;
      font-size: 12px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }

    .onoffswitch-inner:before {
      content: 'ONLINE';
      padding-left: 10px;
      background-color: #ffffff;
      color: #a0d334;
    }

    .onoffswitch-inner:after {
      content: 'OFFLINE';
      padding-right: 10px;
      background-color: #eeeeee;
      color: #999999;
      text-align: right;
    }

    .onoffswitch-switch {
      width: 20px;
      margin: 7px;
      background: #a0d334;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 62px;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
  }

  .box-drop {
    border-left: 1px dotted #ccc;

    a.box-user {
      font-weight: 700;
      align-items: center;
    }

    .box-info {
      font-size: 9px;
      align-items: center;
      color: #6c757d !important;
    }

    a.box-user:hover {
      text-decoration: none !important;
      transition: all 0.2s ease;
    }

    a.box-user.active .arrow-down {
      transform: rotate(180deg);
    }

    .submenu-topo {
      position: fixed;
      background: #fff;
      list-style-type: none;
      padding: 1px;
      margin: 10px 0 0 0;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      width: 100%;
      max-width: 190px;
      right: 5px;
    }

    .submenu-topo li {
      margin: 0px 0;
      border-bottom: 1px solid #f2f2f2;
    }

    .submenu-topo li:last-child {
      border-bottom: none;
    }

    .submenu-topo li a {
      display: inline-block;
      padding: 8px 15px;
      width: 100%;
    }

    .submenu-topo li a:hover {
      background: #f9f9f9;
      text-decoration: none;
    }

    .submenu-topo li a:last-child {
      border-bottom: none;
    }
  }

  .b-avatar-badge {
    padding: 0.35em;
    border-radius: 0.4em;
    font-size: 80%;
    font-weight: 500;
  }
}
</style>
