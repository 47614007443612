<template>
  <div>
    <b-row class="bg-white">
      <b-col cols="12" class="my-3">
        <b-alert variant="info" show>
          <b>Atenção:</b> A nova senha deve possuir no mínimo 6 dígitos, contendo letras números e pelo menos 1 caractere especial (Ex: @, $, %, #, -, etc)
        </b-alert>
      </b-col>

      <b-col cols="12" class="my-3">
        <b-row align-v="center" align-h="between">
          <b-col cols="3" class="pr-0">
            <label class="mb-0">Digite sua Senha atual:</label>
          </b-col>
          <b-col cols="9" class="pl-0">
            <b-form-input type="password" v-model="senhaAntiga" required />
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" class="my-3">
        <b-row align-v="center" align-h="between">
          <b-col cols="3" class="pr-0">
            <label class="mb-0">Digite a nova senha:</label>
          </b-col>
          <b-col cols="9" class="pl-0">
            <b-form-input type="password" v-model="senha1" required />
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" class="my-3">
        <b-row align-v="center" align-h="between">
          <b-col cols="3" class="pr-0">
            <label class="mb-0">Confirme a nova senha:</label>
          </b-col>
          <b-col cols="9" class="pl-0">
            <b-form-input type="password" v-model="senha2" required />
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12">
        <b-row>
          <b-col />
          <b-col cols="4">
            <b-btn block variant="primary" @click="salvarSenha">Salvar</b-btn>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { getAuthHeader } from "~/services/auth";

export default {
  name: "AlterarSenha",
  props: {
    usuarioId: {
      type: Number,
    }
  },
  data() {
    return {
      senhaAntiga: null,
      senha1: null,
      senha2: null,
      errors: [],
    }
  },
  methods: {
    salvarSenha() {
      this.errors = []
      if (this.senhaAntiga === null || this.senha1 === null || this.senha2 === null) {
        alert("Preencha todos os campos!")
        return
      }

      if (this.senha1 != this.senha2) {
        alert("Confirme corretamente a nova senha!")
        return
      }

      if (this.senha1.toString() == this.senhaAntiga.toString()) {
        alert("Erro, nova senha é igual a antiga")
        return
      }

      const senha = {
        'senhaAntiga': this.senhaAntiga,
        'novaSenha1': this.senha1,
        'novaSenha2': this.senha2
      }

      let usuarioId = this.usuarioId || this.$store.state.me.usuarioId;

      this.$axios
        .$patch(`/auth/trocarSenha/${usuarioId}`, senha, { headers: getAuthHeader() })
        .then((result) => {
          this.senhaAntiga = null
          this.senha1 = null
          this.senha2 = null

          this.$bvToast.toast(`Sua senha foi alterada com sucesso`, {
            title: 'Senha Alterada',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true
          });

          this.$bvModal.hide('change-senha-user');

        })
        .catch((err) => {
          this.$bvToast.toast(`${err.response.data.errors[0]}`, {
            title: 'Erro na Operação',
            autoHideDelay: 5000,
            variant: 'info',
            solid: true
          });
        });

    },
  }
}
</script>